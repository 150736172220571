// src/client/build/register.ts
import {Workbox, messageSW} from "workbox-window";
var autoUpdateMode = "true";
var auto = autoUpdateMode === "true";
function registerSW(options = {}) {
  const {
    immediate = false,
    onNeedRefresh,
    onOfflineReady
  } = options;
  let wb;
  let registration;
  const updateServiceWorker = async (reloadPage = true) => {
    if (!auto) {
      if (reloadPage) {
        wb == null ? void 0 : wb.addEventListener("controlling", (event) => {
          if (event.isUpdate)
            window.location.reload();
        });
      }
      if (registration && registration.waiting) {
        await messageSW(registration.waiting, {type: "SKIP_WAITING"});
      }
    }
  };
  if ("serviceWorker" in navigator) {
    wb = new Workbox("/sw.js", {scope: "/"});
    wb.addEventListener("activated", (event) => {
      if (event.isUpdate)
        auto && window.location.reload();
      else
        onOfflineReady == null ? void 0 : onOfflineReady();
    });
    if (!auto) {
      const showSkipWaitingPrompt = () => {
        onNeedRefresh == null ? void 0 : onNeedRefresh();
      };
      wb.addEventListener("waiting", showSkipWaitingPrompt);
      wb.addEventListener("externalwaiting", showSkipWaitingPrompt);
    }
    wb.register({immediate}).then((r) => registration = r);
  }
  return updateServiceWorker;
}
export {
  registerSW
};
